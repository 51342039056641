import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query ActionItems {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Action Items',
    subtitle: `The team members across your organization spend countless hours a week assessing and reporting on all sorts of initiatives, like Inspections, Incident Reporting, Hazard ID's, and all sorts of other forms. And they drum up deficiencies - gotcha's that if left unattended could lead to further incidents. The need to be handled. And you need to know they're handled.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: '/features/action-items/action-items-banner.png'
  }

  const seo = {
    title: 'Workhub | Free Action Item Management Software',
    description: "Our free online Safety & Compliance software let's you create Action Items based on Incident Reporting, Forms, Inspections + more, so that you can deal with deficiences in your workplace.",
    image: '/features/action-items/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Assign",
      para: "Identify deficiencies and corrective actions and assign them to workers. ",
    },
    {
      title: "Notify",
      para: "The system notifies workers that they have been assigned an Action Item via SMS or email.",
    },
    {
      title: "Corrective Actions",
      para: "Workers attach photos, add comments and more, to ultimately complete Action Items.",
    },
    {
      title: "Verify",
      para: "Administrators verify that Action items are completed properly and sign-off once they are complete. ",
    },

  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />
      <main className="feature-main">

        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="\features\action-items\action-items-cartoon.png"
                    alt="Action Items Cartoon"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <p>As each of these ongoing tasks and forms are submitted by your workers, administrators review their content, and if a worker hasn't already done so, the administrator can add one or more action items. The action item is assigned to an individual, a due date is chosen, and a priority level helps prioritize it against everything else that needs doing. The individual can optionally be notified by email or text, but regardless, the action item will show up in their Action Item List. Reminders get added as the action item is coming due (or past due). </p>



              </div>
            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">



                <p>The individual assigned the task, then has the ability to begin logging comments (such as work-in-progress) and ultimately marking the item as complete (which requires an explanation). The item can be reassigned, but the system keeps a log of any changes.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="\features\action-items\action-items-computer.png"
                    alt="Action Items Computer View"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="\features\action-items\action-items-tablet.png"
                    alt="Action Items Tablet View"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

              <p>Once complete, each action item needs to be signed off by an administrator. The system keeps a nice log of all action items throughout the various stages. The objective, of course, is to see every deficiency corrected and signed off, making your workplace a little safer. It also doesn't hurt that you or your auditor can navigate back to the source document for each action item in the list.</p>

              </div>
            </div>
          </div>
        </section>

          {/* FEATURE OVERVIEW */}

  <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>


    </Layout>
  )
}